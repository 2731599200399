import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
const appConfig = require('../../../src/data.json');

function IntroSection(props) {
  return (
    <Wrapper img="/images/about-intro.jpg">
      <div className="bg-img" />
      <div className="text">
        <h3>為您精心調製的專業印刷Bar！</h3>
        <p>
          我們是50年經驗的多元印刷專家，國際ISO雙色彩認證的技術，
          使用最先進的進口設備在線上為您服務。急件大量歡迎詢問，並有企業客戶專人服務。提供信用卡刷卡、轉帳、超商代碼繳費、預繳加值、月結付款等多元支付方式。
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  & > .bg-img {
    flex: 1;
    max-width: 640px;
    width: 100%;
    height: 507px;

    background-image: url(${(props) => props.img});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  & > .text {
    flex: 1;
    padding: 40px 40px 40px 64px;

    & > h3 {
      margin: 0px;
      padding-bottom: 41px;

      color: ${appConfig.colors.main};
      font-weight: bold;
      font-size: 32px;
      line-height: 1.5;
    }

    & > p {
      max-width: 556px;
      width: 100%;
      color: ${appConfig.colors.text};
      font-weight: 500;
      font-size: 18px;
      line-height: 2;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;

    & > .bg-img {
      flex: none;
      height: 300px;
    }

    & > .text {
      padding: 40px;

      & > h3 {
        padding-bottom: 20px;
      }

      & > p {
        font-size: 14px;
      }
    }
  }
`;

export default IntroSection;
