import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Button as WidgetButton} from '../../../src/Widgets';
const appConfig = require('../../../src/data.json');

function AboutSection(props) {
  return (
    <Wrapper>
      <TitleContainer>
        <h2>ABOUT US</h2>
        <h3>關於我們</h3>
      </TitleContainer>
      <ImageContainer>
        <div className="mask" />
        <div className="content">
          <div className="text">
            印刷吧! PrintBar
            是依50年的專業經驗，調製出適合客人喜好及心境的完美調酒為比喻。
            <br />
            完美的調酒是經由調酒職人的經驗及技術，在熟悉所有酒種、配料及器具後，
            <br />
            參考客人的喜好及心境調製出來的精華。相同的，印刷吧!
            <br />
            PrintBar
            透過50年累積的職人工匠印刷技術以及國際ISO的色彩認證，充分掌握油墨及被印物的特性，
            <br />
            透過先進的軟硬體設備，精準控制所有造成色偏的不定因素，達到客人想要的藝品。
          </div>
          <WidgetButton onClick={() => navigate('/products')}>
            全部商品
          </WidgetButton>
        </div>
      </ImageContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const TitleContainer = styled.div`
  padding: 30px 20px;
  background-color: ${appConfig.colors.third};
  text-align: center;

  & > h2 {
    margin: 0px 1px 0px 0px;
    color: ${appConfig.colors.main};
    font-size: 36px;
    font-weight: 900;
  }

  & > h3 {
    margin: 0px;
    color: ${appConfig.colors.main};
    font-size: 24px;
    font-weight: bold;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 588px;

  background-image: url('/images/home-service-place.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  & > .mask {
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100%;

    background-color: rgba(86, 86, 86, 0.5);
    filter: brightness(0.2);
  }

  & > .content {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 0%);

    padding: 80px 0px 40px 0px;
    max-width: 788px;
    width: 100%;
    line-height: 1.88;
    text-align: center;
    font-size: 16px;
    color: #ffffff;

    & > .text {
      opacity: 0.8;
      letter-spacing: 0.5px;
      font-weight: 300;
    }

    @media screen and (max-width: 900px) {
      max-width: 450px;
      min-height: 550px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  & button {
    border-radius: 10px;
    margin-top: 48px;

    min-width: 210px;
    background-color: ${appConfig.colors.third};
    font-weight: 500;
    color: ${appConfig.colors.main};
    border: 0;

    :hover {
      background-color: white;
      color: ${appConfig.colors.main};
    }
  }

  @media screen and (max-width: 900px) {
    min-height: 550px;
  }
`;

export default AboutSection;
