import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const FEATURE_LIST = [
  {name: '國際雙ISO\n色彩品質認證', img: '/images/about-diploma.png'},
  {name: '提供多種\n設計公版', img: '/images/about-blackboard.png'},
  {name: '專人一對一\n雲端諮詢服務', img: '/images/about-conversation.png'},
  {name: '企業客戶\n服務方案', img: '/images/about-social.png'},
];
const MAX_WIDTH = appConfig.maxContentWidth;

function FeatureSection(props) {
  return (
    <Wrapper>
      <div className="row">
        {FEATURE_LIST.map((data, index) => {
          if (index <= 1) {
            return <FeatrueItem data={data} />;
          }

          return null;
        })}
      </div>
      <div className="row">
        {FEATURE_LIST.map((data, index) => {
          if (index >= 2) {
            return <FeatrueItem data={data} />;
          }

          return null;
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 32px;
  background-color: ${appConfig.colors.third};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  transform: translateY(50%);

  max-width: ${MAX_WIDTH}px;
  width: 100%;
  margin: 0 auto;

  & > .row {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1000px) {
    transform: translateY(0%);
  }
`;

export default FeatureSection;

function FeatrueItem(props) {
  const {data} = props;

  return (
    <ItemWrapper>
      <div className="icon">
        <img alt="not found" src={data.img} />
      </div>
      <div className="text">{data.name}</div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  margin: 5px;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .icon {
    margin-right: 10px;
    width: 92px;
    height: 92px;
    border-radius: 50%;
    background-color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 60px;
      height: 60px;
    }
  }

  & > .text {
    min-width: 110px;
    white-space: pre-wrap;
    color: ${appConfig.colors.main};
    font-size: 18px;
    line-height: 1.44;
  }
`;
