import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

function HistorySection(props) {
  return (
    <Wrapper>
      <TitleContainer>
        <h2>HISTORY</h2>
        <h3>發展沿革</h3>
      </TitleContainer>
      <ContentContainer>
        <div className="text">{`印刷吧! PrintBar 的核心技術成員是由50年的經驗的印刷職人所組成的印刷服務團隊，經過早期相片印刷、傳統製版印刷、到數位化印刷、再到多材質印刷，傳承唯一不變的色彩技術，透過現代化數位技術達到科學數據化的控色標準，進而接軌國際色彩認證。達到客戶要求的品質為第一要件的服務承諾，一直不變！\n\r\n\r希望將多年來線下的服務經驗及品質帶到線上，讓服務國際品牌的品質也可以給更多人享用。
一起來印刷吧！！～`}</div>
        <div className="gutter" />
        <StaticImage
          filename="about-history.jpg"
          extraStyle={{flex: 1}}
          alt="about-history"
        />
      </ContentContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${appConfig.colors.main};
  padding: 180px 40px 65px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1000px) {
    padding: 40px 20px;
  }
`;

const TitleContainer = styled.div`
  margin: 0 auto 50px;
  width: 250px;
  border-bottom: 1px solid ${appConfig.colors.third};
  padding-bottom: 28px;
  text-align: center;

  & > h2 {
    margin: 0px;
    font-weight: bold;
    font-size: 42px;
    color: ${appConfig.colors.third};
  }

  & > h3 {
    margin: 0px;
    font-weight: bold;
    font-size: 24px;
    color: ${appConfig.colors.third};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: ${appConfig.maxContentWidth}px;
  width: 100%;
  margin-bottom: 20px;

  & > .gutter {
    flex: 0 0 30px;
  }

  & > .text {
    flex: 1;

    width: 100%;
    white-space: pre-wrap;
    font-size: 18px;
    color: #ffffff;
    line-height: 1.78;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    & > .bg-img {
      flex: none;
    }
  }
`;

export default HistorySection;
