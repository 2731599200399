import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';
import {ArrowLeft} from '@styled-icons/bootstrap/ArrowLeft';
const appConfig = require('../../../src/data.json');

const PRODUCT_CATEGORIES = [
  {name: '大圖輸出', img: '/images/about-product-icon1.jpg'},
  {name: '包膜', img: '/images/about-product-icon2.jpg'},
  {name: '立牌', img: '/images/about-product-icon3.jpg'},
  {name: '地墊', img: '/images/about-product-icon4.jpg'},
  {name: '個人化產品', img: '/images/about-product-icon5.jpg'},
  {name: '軟裝類', img: '/images/about-product-icon6.jpg'},
  {name: '無框畫', img: '/images/about-product-icon7.jpg'},
  {name: '壁紙(貼)類', img: '/images/about-product-icon8.jpg'},
  {name: 'Tshirt', img: '/images/about-product-icon9.jpg'},
];

function CategoryCarousel(props) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {PRODUCT_CATEGORIES.map((category) => (
          <CategoryWrapper image={category.img}>
            <div className="img" />
            <div className="text">{category.name}</div>
          </CategoryWrapper>
        ))}
      </Slider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 50px 25px;
  padding: 20px;
  text-align: center;
  position: relative;

  @media screen and (max-width: 320px) {
    margin: 25px;
  }
`;

const CategoryWrapper = styled.div`
  max-width: 200px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .img {
    margin: 5px 10px 20px 10px;
    border-radius: 50%;
    width: 170px;
    height: 170px;

    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    box-shadow: 1px 1px 8px -2px #555;

    @media screen and (max-width: 1200px) {
      width: 160px;
      height: 160px;
    }

    @media screen and (max-width: 1000px) {
      width: 180px;
      height: 180px;
    }

    @media screen and (max-width: 450px) {
      width: 250px;
      height: 250px;
    }

    @media screen and (max-width: 320px) {
      width: 200px;
      height: 200px;
    }
  }

  & > .text {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: ${appConfig.colors.text};
  }

  @media screen and (max-width: 450px) {
    max-width: 250px;
  }

  @media screen and (max-width: 320px) {
    max-width: 200px;
  }
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: ${appConfig.colors.main};
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0px;
`;

export default CategoryCarousel;

function NextArrow(props) {
  const {onClick} = props;
  return (
    <ArrowWrapper
      onClick={onClick}
      style={{right: -45, transform: 'translateY(300%)'}}>
      <ArrowRight size={30} color="#ffffff" />
    </ArrowWrapper>
  );
}

function PrevArrow(props) {
  const {onClick} = props;
  return (
    <ArrowWrapper
      onClick={onClick}
      style={{left: -45, transform: 'translateY(300%)'}}>
      <ArrowLeft size={30} color="#ffffff" />
    </ArrowWrapper>
  );
}
