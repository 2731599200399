import React, {useContext, useState} from 'react';
import {Context} from '../../../src/AppContext';
import {Link, navigate} from 'gatsby';
import {Input, message} from 'antd';
import styled from 'styled-components';
import {Button as WidgetButton} from '../../../src/Widgets';

import AboutSection from './AboutSection';
import ServiceSection from './ServiceSection';
import CategoryCarousel from './CategoryCarousel';
import IntroSection from './IntroSection';
import FeatureSection from './FeatureSection';
import HistorySection from './HistorySection';
import ContactSection from './ContactSection';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

class ErrAboutPage extends Error {}

export default function AboutPage(props) {
  return (
    <Wrapper>
      <div className="top-section">
        <AboutSection />
        <ServiceSection />
      </div>
      <div className="section constraint" style={{marginBottom: 70}}>
        <div className="title">
          <h2>CATEGORY</h2>
          <h3>品項類別</h3>
        </div>
        <CategoryCarousel />
      </div>
      <div className="section constraint">
        <IntroSection />
      </div>
      <FeatureSection />
      <div className="section">
        <HistorySection />
      </div>
      <div className="section constraint">
        <div className="title">
          <h2>CONTACT US</h2>
          <h3>聯絡印刷吧!</h3>
        </div>
        <div className="info-section">
          <p>
            {`對於我們有任何問題與報價需求，歡迎填寫表單與我們聯絡！\n也可直接來信至：info@printbar.tw\n\n 客服電話：(02)7730-1160\n Line@ : @printbar\n IG : @printbartw\n FB : @printbar.tw`}
          </p>
        </div>
      </div>
      <ContactSection />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;

  & .constraint {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 0 auto;
    padding: 0px 20px;
  }

  & > .top-section {
    margin-bottom: 200px;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      margin-bottom: 50px;
    }
  }

  & > .section {
    margin-bottom: 50px;
    & > .title {
      margin: 0 auto 50px;
      width: 280px;
      border-bottom: 1px solid ${appConfig.colors.text};
      padding-bottom: 28px;
      text-align: center;

      & > h2 {
        margin: 0px;
        font-weight: bold;
        font-size: 42px;
        color: ${appConfig.colors.main};
      }

      & > h3 {
        margin: 0px;
        font-weight: bold;
        font-size: 24px;
        color: ${appConfig.colors.main};
      }
    }

    & > .info-section {
      text-align: center;
      font-size: 18px;
      line-height: 1.67;
      white-space: pre-wrap;
      color: ${appConfig.colors.text};
    }
  }
`;
