import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
const appConfig = require('../../../src/data.json');

const SERVICE_LIST = [
  {name: '兒童相關產品', img: '/images/about-child.jpg'},
  {name: '室內裝潢產品', img: '/images/about-room.jpg'},
  {name: '行銷廣告類產品', img: '/images/about-ad.jpg'},
  {name: '生活相關產品', img: '/images/about-life.jpg'},
];

const MAX_WIDTH = appConfig.maxContentWidth;

function ServiceSection(props) {
  return (
    <Wrapper>
      <div className="row">
        {SERVICE_LIST.filter((_, index) => index <= 1).map((data) => (
          <ServiceItem data={data} />
        ))}
      </div>
      <div className="row">
        {SERVICE_LIST.filter((_, index) => index >= 2).map((data) => (
          <ServiceItem data={data} />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  transform: translate(-50%, -55%);

  display: flex;
  justify-content: space-between;

  max-width: ${MAX_WIDTH + 40}px;
  width: 100%;
  margin: 0 auto;

  & .row {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;

    position: relative;
    left: 0%;
    transform: translate(0%, 0%);
  }
`;

export default ServiceSection;

function ServiceItem(props) {
  let {data} = props;
  return (
    <ItemWrapper
      img={data.img}
      onClick={() => navigate(`/products?name=${data.name}`)}>
      <div className="bg-img" />
      <div className="title">{data.name}</div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  max-width: 285px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  cursor: pointer;

  box-shadow: 1px 1px 6px -2px #555;

  & > .bg-img {
    flex: 1;
    width: 100%;
    min-height: 270px;

    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  & > .title {
    width: 100%;

    padding: 16px;
    background-color: ${appConfig.colors.main};
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    color: ${appConfig.colors.third};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  @media screen and (max-width: 1200px) {
    max-width: 250px;
    margin: 10px;

    & > .bg-img {
      min-height: 263px;
    }
    & > .title {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 900px) {
    margin: 20px;
  }
`;
